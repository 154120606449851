<script>
	import { required, email, maxLength, numeric, minLength } from "vuelidate/lib/validators";
	import appConfig from "@/app.config";
	import Vue from 'vue';
	import axios from 'axios';
	import VueAxios from 'vue-axios';
	import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

	Vue.use(VueAxios, axios);
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
	axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_BASE_URL;

	import VueSession from 'vue-session';
	Vue.use(VueSession);

	/**
	 * Login component
	 */
	export default {
		page: {
			title: "Login",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			SyncLoader,
		},
		data() {
			return {
				email: "",
				password: "",
				submitted: false,
				authError: "sds",
				tryingToLogIn: false,
				isAuthError: false,
				mobileNumber: "",
				otp: "",
				isOtpSend: false,
				isValidOtp: true,
				isGetOtpDisabled: false,
				isAuthenticated: false,
			};
		},
		validations: {
			email: {
				required,
				email,
			},
			password: {
				required,
			},
			mobileNumber: {
				required,
				maxLength: maxLength(10),
				minLength: minLength(10),
				numeric,
			},
			otp: {
				required,
				maxLength: maxLength(6),
				minLength: minLength(6),
				numeric,
			},
		},
		computed: {
			notification() {
				return this.$store ? this.$store.state.notification : null;
			},
			notificationAutoCloseDuration() {
				return this.$store && this.$store.state.notification ? 5 : 0;
			},
		},
		beforeMount: function() {
			if (this.$route.query.auth != undefined) {
				this.isAuthenticated = true;
				this.isAuthError = false;
				this.autoLogin();
			}
		},
		mounted() {
			document.body.classList.add("authentication-bg");
		},
		methods: {
			autoLogin: function() {
				this.axios.post('auto-login', {
					hashedUrl: this.$route.query.auth,
				}).then(result => {
					let status = result.data.responseCode;
					if (status == 200) {
						if (result.data) {
							//console.log(result.data.data.adminData);
							// store user details and jwt token in local storage to keep user logged in between page refreshes
							localStorage.setItem('user', JSON.stringify(result.data.data.loginDetailsAdmin));
							localStorage.setItem('authToken', result.data.data.token);
							this.$router.push('/tables/attendance');
						}
					}
				}).catch(error => {
					console.log(error)
				});
			},
			// Try to log the user in with the username
			// and password they provided.
			tryToLogIn() {
				``
				this.submitted = true;
				// stop here if form is invalid
				this.$v.$touch();

				if (this.$v.$invalid) {
					return;
				} else {
					const { email, password } = this;
					if (email && password) {
						this.axios.post('login', {
							email: email,
							password: password
						}).then((result) => {
							let status = result.data.responseCode;
							if (status == 200) {
								if (result.data) {
									//console.log(result.data.data.adminData);
									// store user details and jwt token in local storage to keep user logged in between page refreshes
									localStorage.setItem('user', JSON.stringify(result.data.data.adminData));
									localStorage.setItem('authToken', result.data.data.token);
									this.$router.push('/tables/attendance');
								}
							} else {
								this.isAuthError = true;
								this.authError = result.data.message;
							}
						}).catch(function (error) {
							alert(error);
						});
					}
				}
			},
			logingWithOtp: function() {
				this.submitted = true;
				// stop here if form is invalid
				this.$v.$touch();
				if (!this.$v.otp.$invalid) {
					this.axios.post('login-verifyotp', {
						phoneNumber: this.mobileNumber,
						otp: this.otp
					}).then((result) => {
						const status = result.data.responseCode;
						if (status == 200) {
							localStorage.setItem('user', JSON.stringify(result.data.data.loginDetailsAdmin));
							localStorage.setItem('authToken', result.data.data.token);
							this.$router.push('/tables/attendance');
						}
						if (status == 400) {
							this.isValidOtp = false;
						}
					}).catch((error) => {
						console.log(error);
					});
				}
			},
			getOtp: function() {
				this.submitted = true;
				this.$v.$touch();
				if (!this.$v.mobileNumber.$invalid) {
					this.submitted = false;
					this.axios.post('login-otp', {
						phoneNumber: this.mobileNumber,
					}).then((result) => {
						console.log(result.data.response)
						this.isOtpSend = true;
					}).catch((error) => {
						console.log(error)
					});
				}
			},
		},
	};
</script>
<template>
	<div>
		<div class="account-pages my-5 pt-sm-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="text-center">
							<router-link to="/" class="mb-5 d-block auth-logo">
								<img src="@/assets/images/munshi-color.png" alt height="90" class="logo logo-dark" style="object-fit:cover;" />
								<img src="@/assets/images/munshi-color.png" alt height="90" class="logo logo-light" style="object-fit:cover;" />
							</router-link>
						</div>
					</div>
				</div>
				<div class="row align-items-center justify-content-center">
					<div class="col-md-1" v-if="isAuthenticated">
						<sync-loader :loading="isAuthenticated" :color="f12911"></sync-loader>
					</div>
					<div class="col-md-8 col-lg-6 col-xl-5" v-else>
						<div class="card">
							<div class="card-body p-4">
								<div class="text-center mt-2">
									<h5 class="text-primary" style="color: #f12d11 !important;">Welcome Back!</h5>
									<p class="text-muted">Sign in to continue to Munshi.</p>
								</div>
								<div class="p-2 mt-4">
									<b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
									<div v-if="notification.message" :class="'alert ' + notification.type">
										{{ notification.message }}
									</div>
									<b-form @submit.prevent="logingWithOtp">
										<b-input-group id="input-group-1" class="mb-3" label="Mobile Number" label-for="mobile-number">
											<b-form-input id="mobile-number" v-model="mobileNumber" type="text" placeholder="Enter Mobile Number"
												:class="{ 'is-invalid': submitted && $v.mobileNumber.$error }" v-on:keyup.enter="getOtp"></b-form-input>
											<b-input-group-append>
												<b-button type="button" variant="outline-info" @click="getOtp">Get OTP</b-button>
											</b-input-group-append>
											<div v-if="submitted && $v.mobileNumber.$error" class="invalid-feedback">
												<div v-if="!$v.mobileNumber.required">Mobile number is required.</div>
												<div v-else-if="!$v.mobileNumber.numeric">Please enter valid mobile number.</div>
												<div v-else-if="!$v.mobileNumber.maxLength || !$v.mobileNumber.minLength">Mobile number must be of 10 digits</div>
											</div>
										</b-input-group>
										<b-form-group id="input-group-2" class="mb-3" v-if="isOtpSend">
											<label for="input-2">OTP</label>
											<b-form-input id="input-2" v-model="otp" type="text" placeholder="Enter OTP" :class="{
												'is-invalid': submitted && $v.otp.$error || !isValidOtp,
											}"></b-form-input>
											<div class="invalid-feedback">
												<div v-if="!$v.otp.required">OTP is required.</div>
												<div v-if="!$v.otp.numeric">OTP must be number only.</div>
												<div v-if="!$v.otp.maxLength || !$v.otp.minLength">OTP must be of 6 digit</div>
												<div v-if="!isValidOtp">Otp is not correct!</div>
											</div>
										</b-form-group>
										<div class="mb-4">
											<!-- <input class="form-check-input" type="checkbox" id="autoSizingCheck3" v-model="isTncAgreed"> -->
											<label class="form-check-label" for="autoSizingCheck3" style="margin-left: 5px;">By continuing "you agree to terms and conditions"</label>
										</div>
										<div class="mt-3 text-end" v-if="isOtpSend">
											<b-button type="submit" variant="primary" class="btn btn-outline-primary mt-4"
												style="background-color: #EB455F;color: #fff;">Log In</b-button>
										</div>
									</b-form>
								</div>
								<!-- end card-body -->
							</div>
							<!-- end card -->
						</div>
						<div class="mt-5 text-center">
							<p>
								© {{ new Date().getFullYear() }} Mobcast Innovations Pvt Ltd.
							</p>
						</div>
						<!-- end row -->
					</div>
					<!-- end col -->
				</div>
				<!-- end row -->
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	.authentication-bg {
		background-color: rgb(216 226 245 / 25%) !important;
	}
	.btn, .btn:active, .btn:focus, input:active, input:focus {
		outline: none !important;
		box-shadow: none !important;
	}
	.input-group .form-control {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		border-right: 0 none;
	}
	.input-group .btn {
		border: 1px solid #ced4da;
	}
	.input-group .btn {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-left: 0 none;
	}
	.input-group .btn.btn-outline-info {
		background-color: #fff;
		color: #495057;
	}
	.form-control.is-invalid + .input-group-append .btn {
		border-color: #f46a6a;
		color: #f46a6a;
	}
</style>
